import React, { useEffect, useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import { InputText } from 'primereact/inputtext';
import { HousePlanService } from "../../controllers/HousePlanService";
import { DefaultErrorMessage, MaximumRecordsPerPage, spreadElements } from "../../common/SystemConstants";
import { showServerError } from "../../common/MessageDialog";
import { Paginator } from "primereact/paginator";
import { SearchFilter } from "../../common/SearchFilter";
import { Messages } from "primereact/messages";
import { formatBooleanFields, reloadCurrentPage, ThousandsSeparatorNoCurrency } from "../../common/Utils";
import { Dropdown } from 'primereact/dropdown';
import { Editor } from 'primereact/editor';
import { SizeService } from "../../controllers/SizeService";
import { StyleService } from "../../controllers/StyleService";
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { UIHomePath, UIPathManageHousePlan } from "../../routes/Frontend";
import { useNavigate } from "react-router-dom";
import { PlotSizeService } from '../../controllers/PlotSizeService';


const ViewHousePlans = () => {
    let emptyRecord = {
        id: "",
        planId: "",
        name: "",
        freePlan: false,
        description: "",
        size: null,
        squareMeters: 0,
        style: null,
        width: 0,
        length: 0,
        plotSize: null
    };

    const [records, setRecords] = useState(null);
    const [recordDialog, setRecordDialog] = useState(false);
    const [deleteRecordDialog, setDeleteRecordDialog] = useState(false);
    const [record, setRecord] = useState(emptyRecord);
    const [selectedRecord, setSelectedRecord] = useState(null);
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const message = useRef();
    const [searchValue, setSearchValue] = useState("");
    const [totalItems, setTotalItems] = useState(0);
    const [first, setFirst] = useState(0);
    const [limit, setLimit] = useState(MaximumRecordsPerPage);
    const [id, setId] = useState(null);
    const [name, setName] = useState(null);
    const [planId, setPlanId] = useState(null);
    const [housePlanStyle, setHousePlanStyle] = useState(null);
    const [housePlanSize, setHousePlanSize] = useState(null);
    const [housePlotSize, setHousePlotSize] = useState(null);
    const [description, setDescription] = useState(null);
    const [squareMeters, setSquareMeters] = useState(0);
    const [freePlan, setFreePlan] = useState(false);
    const [length, setLength] = useState(0);
    const [width, setWidth] = useState(0);

    // Dropdown values
    const [sizes, setSizes] = useState([]);
    const [styles, setStyles] = useState([]);
    const [plotSizes, setPlotSizes] = useState([]);
    const navigate = useNavigate();

    const fetchRecordsFromServer = () => {
        setIsLoading(true);
        new HousePlanService().getRecords(searchValue, first, limit)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setRecords(value.records);
                        setTotalItems(value.totalItems);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });

        // Load sizes
        new SizeService().getRecords(null, 0, -1)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setSizes(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });

        // Load styles
        new StyleService().getRecords(null, 0, -1)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setStyles(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });

        // Load plot sizes
        new PlotSizeService().getRecords(null, 0, -1)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setPlotSizes(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });

    };

    const onSubmitFilter = () => {
        setSearchValue(searchValue);
        fetchRecordsFromServer();
    };

    const resetFilters = () => {
        setIsLoading(true);
        setSearchValue("");
        fetchRecordsFromServer();
    };

    useEffect(() => {
        fetchRecordsFromServer();
    }, []);

    const onPageChange = (e) => {
        let offset = e.page * MaximumRecordsPerPage;
        setFirst(e.first);
        setLimit(MaximumRecordsPerPage);
        fetchRecordsFromServer();
    };

    const rowIndexTemplate = (rowData, props) => {
        let index = first + parseInt(props.rowIndex + 1, 10);
        return (
            <React.Fragment>
                <span>{index}</span>
            </React.Fragment>
        );
    };
    const openNew = () => {
        setRecord(emptyRecord);
        setSubmitted(false);
        setRecordDialog(true);
        setId(null);
        setName(null);
        setPlanId(null);
        setDescription(null);
        setHousePlanStyle(null);
        setSquareMeters(0);
        setHousePlanSize(null);
        setFreePlan(false);
        setWidth(0);
        setLength(0);
        setHousePlotSize(null);
    };

    const hideDialog = () => {
        setSubmitted(false);
        setRecordDialog(false);
    };

    const hideDeleteRecordDialog = () => {
        setDeleteRecordDialog(false);
    };

    const saveRecord = () => {
        setSubmitted(true);
        setRecordDialog(false);

        if (name.trim() && description.trim()) {
            setIsLoading(true);
            let data = {
                id: id,
                planId: planId,
                name: name,
                freePlan: freePlan,
                description: description,
                size: housePlanSize,
                squareMeters: squareMeters,
                style: housePlanStyle,
                width: width,
                length: length,
                plotSize: housePlotSize
            }


            new HousePlanService().saveRecord(data).then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (!response.ok) {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    } else {
                        setId(null);
                        setName(null);
                        setPlanId(null);
                        setDescription(null);
                        setHousePlanStyle(null);
                        setSquareMeters(0);
                        setHousePlanSize(null);
                        setFreePlan(false);
                        setWidth(0);
                        setLength(0);
                        setHousePlotSize(null);
                        fetchRecordsFromServer();
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
        }
    };

    const editRecord = (record) => {
        setId(record.id);
        setName(record.name);
        setPlanId(record.planId);
        setDescription(record.description);
        setHousePlanStyle(record.style);
        setSquareMeters(record.squareMeters);
        setHousePlanSize(record.size);
        setFreePlan(record.freePlan);
        setWidth(record.width);
        setLength(record.length);
        setHousePlotSize(record.plotSize);
        setRecordDialog(true);
    };

    const manageRecord = (record) => {
        navigate(UIPathManageHousePlan, { state: record });
    };

    const confirmDeleteRecord = (record) => {
        setId(record.id);
        setRecord(record);
        setDeleteRecordDialog(true);
    };

    const deleteRecord = () => {
        setIsLoading(true);
        new HousePlanService().deleteRecord(id)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        fetchRecordsFromServer();
                        hideDeleteRecordDialog();
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    };

    const nameBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Name</span>
                {rowData.name}
            </>
        );
    };
    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {ThousandsSeparatorNoCurrency(rowData.price)}
            </>
        );
    };

    const planIdBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Plan Id</span>
                {rowData.planId}
            </>
        );
    };
    const squareMetersBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Square Meters</span>
                {rowData.squareMeters}
            </>
        );
    };

    const freePlanBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Free Plan</span>
                {formatBooleanFields(rowData.freePlan)}
            </>
        );
    };
    const styleBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Style</span>
                {rowData.style.name}
            </>
        );
    };
    const sizeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Size</span>
                {rowData.size.name}
            </>
        );
    };
    const housePlanStatusBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Status</span>
                {rowData.housePlanStatus}
            </>
        );
    };

    const actionBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{ float: "right" }}>
                <Button icon="pi pi-eye" className="p-button-rounded p-button-info mr-2" onClick={() => manageRecord(rowData)} />
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editRecord(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteRecord(rowData)} />
            </div>
        );
    };

    const recordDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveRecord} />
        </>
    );
    const deleteRecordDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteRecordDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteRecord} />
        </>
    );



    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Messages ref={message} />

                    <div className=" col-12 d-flex justify-content-sm-start justify-content-md-end justify-content-lg-end justify-content-xl-end flex-wrap">
                        <div className="mr-auto p-2">
                            <h5 className="p-m-0">Manage House Plans</h5>
                        </div>
                        <SearchFilter
                            searchValue={searchValue}
                            setSearchValue={setSearchValue}
                            showInputSearchBar={true}
                            showAddNew={true}
                            showResetFilter={false}
                            onSubmitFilter={onSubmitFilter}
                            resetFilters={resetFilters}
                            onAddNew={openNew}
                        />
                    </div>

                    <hr />

                    <DataTable
                        ref={dt}
                        value={records}
                        selectionMode="single" selection={selectedRecord}
                        onSelectionChange={(e) => setSelectedRecord(e.value)}
                        rows={20}
                        rowsPerPageOptions={[20, 50, 100]}
                        globalFilter={globalFilter}
                        dataKey="id"
                        paginator={false}
                        className="datatable-responsive"
                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                        paginatorPosition="bottom"
                        paginatorAlwaysVisible="false"
                        emptyMessage="No record found."
                        loading={isLoading}
                        reflow="true"
                        responsiveLayout="scroll">
                        <Column field="Index" header="#" style={{ width: "4rem" }} body={rowIndexTemplate}></Column>
                        <Column field="name" header="Name" body={nameBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                        <Column field="planId" header="Plan Id" body={planIdBodyTemplate} headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                        <Column field="squareMeters" header="Square Meters" body={squareMetersBodyTemplate}></Column>
                        <Column field="style" header="Style" body={styleBodyTemplate}></Column>
                        <Column field="size" header="Size" body={sizeBodyTemplate}></Column>
                        <Column field="price" header="Price" body={priceBodyTemplate}></Column>
                        <Column field="freePlan" header="Free Plan" body={freePlanBodyTemplate}></Column>
                        <Column field="housePlanStatus" header="Status" body={housePlanStatusBodyTemplate} headerStyle={{ width: '10%', minWidth: '8rem' }}></Column>
                        <Column body={actionBodyTemplate}></Column>
                    </DataTable>

                    <Paginator first={first} rows={MaximumRecordsPerPage} totalRecords={totalItems} alwaysShow={false} onPageChange={onPageChange} />

                    <Dialog visible={recordDialog} style={{ width: '700px' }} header="House Plan Details" modal className="p-fluid" footer={recordDialogFooter} onHide={hideDialog}>
                        <div className="grid p-fluid" style={{ marginTop: '10px' }}>
                            <div className="col-12 md:col-12">
                                <div className="field">
                                    <label htmlFor="name">Name</label>
                                    <InputText id="name" value={name}
                                        onChange={(e) => setName(e.target.value || '')}
                                        required autoFocus className={classNames({ 'p-invalid': submitted && !name })} />
                                    {submitted && !name && <small className="p-invalid">Name is required.</small>}
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="size">Size</label>
                                    <Dropdown
                                        id="size"
                                        value={housePlanSize}
                                        onChange={(e) => setHousePlanSize(e.value)}
                                        options={sizes}
                                        optionLabel="name"
                                        placeholder="Select a size" />
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="field">
                                    <label htmlFor="templateType">Style</label>
                                    <Dropdown
                                        id="templateType"
                                        value={housePlanStyle}
                                        onChange={(e) => setHousePlanStyle(e.value)}
                                        options={styles}
                                        optionLabel="name"
                                        placeholder="Select a Style" />
                                </div>
                            </div>

                            <div className="col-12 md:col-4">
                                <div className="field">
                                    <label htmlFor="templateType">Plot Size</label>
                                    <Dropdown
                                        id="templateType"
                                        value={housePlotSize}
                                        onChange={(e) => setHousePlotSize(e.value)}
                                        options={plotSizes}
                                        optionLabel="name"
                                        placeholder="Select a plot size" />
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="width">Width</label>
                                        <InputNumber id="width" value={width} onValueChange={(e) => setWidth(e.value || 0)} required autoFocus className={classNames({ 'p-invalid': submitted && (!width || width < 0) })} />
                                        {submitted && (!width || width < 0) && <small className="p-invalid">Width is required.</small>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-4">
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="length">Length</label>
                                        <InputNumber id="length" value={length} onValueChange={(e) => setLength(e.value || 0)} required autoFocus className={classNames({ 'p-invalid': submitted && (!length || length <= 0) })} />
                                        {submitted && (!length || length <= 0) && <small className="p-invalid">Length is required.</small>}
                                    </div>
                                </div>
                            </div>

                            <div className="col-12 md:col-6">
                                <div className="formgrid grid">
                                    <div className="field col">
                                        <label htmlFor="squareMeters">Square Meters</label>
                                        <InputNumber id="squareMeters" value={squareMeters} onValueChange={(e) => setSquareMeters(e.value || 0)} required autoFocus className={classNames({ 'p-invalid': submitted && (!squareMeters || squareMeters <= 0) })} />
                                        {submitted && (!squareMeters || squareMeters <= 0) && <small className="p-invalid">Size is required.</small>}
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-6">
                                <div className="formgrid grid">
                                    <div className="field col" style={{ ...spreadElements }}>
                                        <label htmlFor="userAsDefault" style={{ paddingRight: "0.5em" }}> Make plan free</label>
                                        <InputSwitch
                                            id="userAsDefault"
                                            checked={freePlan}
                                            onChange={(e) => setFreePlan(e.value)} />
                                    </div>
                                </div>
                            </div>
                            <div className="col-12 md:col-12">
                                <div className="field">
                                    <label htmlFor="description">Description</label>
                                    <Editor id="description" value={description}
                                        onTextChange={(e) => setDescription(e.htmlValue)}
                                        style={{ height: '180px' }} />
                                    {submitted && !description && <small className="p-invalid">Description is required.</small>}
                                </div>
                            </div>
                        </div>
                    </Dialog>

                    <Dialog visible={deleteRecordDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteRecordDialogFooter} onHide={hideDeleteRecordDialog}>
                        <div className="flex align-items-center justify-content-center">
                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                            {record && (
                                <span>
                                    Are you sure you want to delete <b>{record.name}</b>?
                                </span>
                            )}
                        </div>
                    </Dialog>
                </div>
            </div>
        </div>
    )
        ;
};

export default ViewHousePlans;
