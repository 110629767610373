import React, { useEffect, useRef, useState } from 'react';
import { classNames } from 'primereact/utils';
import { DataTable } from 'primereact/datatable';
import { Column } from 'primereact/column';
import { Button } from 'primereact/button';
import { Dialog } from 'primereact/dialog';
import * as SystemConstants from "../../common/SystemConstants";
import { DefaultErrorMessage, spreadElements } from "../../common/SystemConstants";
import { showServerError } from "../../common/MessageDialog";
import { SearchFilter } from "../../common/SearchFilter";
import { Messages } from "primereact/messages";
import { formatBooleanFields, ThousandsSeparatorNoCurrency } from "../../common/Utils";
import { Dropdown } from 'primereact/dropdown';
import { InputNumber } from "primereact/inputnumber";
import { InputSwitch } from "primereact/inputswitch";
import { useLocation } from "react-router-dom";
import { TabPanel, TabView } from 'primereact/tabview';
import { HousePlanDesignCategoryService } from "../../controllers/HousePlanDesignCategoryService";
import { DesignCategoryService } from "../../controllers/DesignCategoryService";
import { FeatureService } from "../../controllers/FeatureService";
import { HousePlanFeatureService } from "../../controllers/HousePlanFeatureService";
import { HousePlanDiscountService } from "../../controllers/HousePlanDiscountService";
import { FileService } from "../../controllers/FileService";
import DOMPurify from 'dompurify';
import { SizeService } from "../../controllers/SizeService";
import { StyleService } from "../../controllers/StyleService";
import { HousePlanService } from "../../controllers/HousePlanService";
import { InputText } from "primereact/inputtext";
import { Editor } from "primereact/editor";
import { DataView } from "primereact/dataview";
import { FileUpload } from "primereact/fileupload";
import { EndpointGetFileBytes, EndpointSaveFile, EndpointSaveHousePlanDesignCategoryWithAttachment, EndpointUploadDocument } from "../../routes/Backend";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFile, faFilePdf } from '@fortawesome/free-solid-svg-icons';
import { PlotSizeService } from '../../controllers/PlotSizeService';


const ViewManageHousePlan = () => {
    const location = useLocation();
    const { state } = location;
    const [activeIndex, setActiveIndex] = useState(0);
    const handleTabChange = (e) => {
        setActiveIndex(e.index);
    };

    // House plan design category
    const [housePlanDesignCategories, setHousePlanDesignCategories] = useState(null);
    const [selectedHousePlanDesignCategory, setSelectedHousePlanDesignCategory] = useState(null);
    const [designCategories, setDesignCategories] = useState(null);
    const [selectedDesignCategory, setSelectedDesignCategory] = useState(null);
    const [pricePerSquareMeter, setPricePerSquareMeter] = useState(null);
    const [useAsDefault, setUseAsDefault] = useState(false);
    const [housePlanDesignCategoryId, setHousePlanDesignCategoryId] = useState(null);
    let emptyHousePlanDesignCategory = {
        id: null,
        housePlan: state,
        designCategory: null,
        useAsDefault: false,
        pricePerSquareMeter: 0
    };
    const [housePlanDesignCategory, setHousePlanDesignCategory] = useState(emptyHousePlanDesignCategory);
    const [housePlanDesignCategoryDialog, setHousePlanDesignCategoryDialog] = useState(false);
    const [deleteHousePlanDesignCategoryDialog, setDeleteHousePlanDesignCategoryDialog] = useState(false);

    // House Plan Features
    const [housePlanFeatures, setHousePlanFeatures] = useState(null);
    const [selectedHousePlanFeature, setSelectedHousePlanFeature] = useState(null);
    const [features, setFeatures] = useState(null);
    const [selectedFeature, setSelectedFeature] = useState(null);
    const [numberOfItems, setNumberOfItems] = useState(0);
    const [position, setPosition] = useState(0);
    const [housePlanFeatureId, setHousePlanFeatureId] = useState(null);
    let emptyHousePlanFeature = {
        id: null,
        housePlan: state,
        feature: null,
        numberOfItems: 0
    };
    const [housePlanFeature, setHousePlanFeature] = useState(emptyHousePlanFeature);
    const [housePlanFeatureDialog, setHousePlanFeatureDialog] = useState(false);
    const [deleteHousePlanFeatureDialog, setDeleteHousePlanFeatureDialog] = useState(false);

    // House Plan Discounts
    const [housePlanDiscounts, setHousePlanDiscounts] = useState(null);
    const [selectedHousePlanDiscount, setSelectedHousePlanDiscount] = useState(null);
    const [discounts, setDiscounts] = useState(null);
    const [selectedDiscount, setSelectedDiscount] = useState(null);
    const [percentageDiscount, setPercentageDiscount] = useState(0);
    const [housePlanDiscountId, setHousePlanDiscountId] = useState(null);
    let emptyHousePlanDiscount = {
        id: null,
        housePlan: state,
        percentage: 0
    };
    const [housePlanDiscount, setHousePlanDiscount] = useState(emptyHousePlanDiscount);
    const [housePlanDiscountDialog, setHousePlanDiscountDialog] = useState(false);
    const [deleteHousePlanDiscountDialog, setDeleteHousePlanDiscountDialog] = useState(false);

    // Drawings
    const [housePlanDrawings, setHousePlanDrawings] = useState(null);
    const [housePlanDocuments, setHousePlanDocuments] = useState(null);
    const [housePlanDrawing, setHousePlanDrawing] = useState(null);
    const [housePlanDrawingId, setHousePlanDrawingId] = useState(null);
    const [deleteHousePlanDrawingDialog, setDeleteHousePlanDrawingDialog] = useState(false);
    const [layout, setLayout] = useState('grid');
    const uploadImageUrl = EndpointSaveFile.replace(":id", state.id);

    // Edit plan
    const [editPlanDialog, setEditPlanDialog] = useState(false);
    const [id, setId] = useState(state.id);
    const [name, setName] = useState(state.name);
    const [housePlanStatus, setHousePlanStatus] = useState(state.housePlanStatus);
    const [planId, setPlanId] = useState(state.planId);
    const [housePlanStyle, setHousePlanStyle] = useState(state.style);
    const [housePlanSize, setHousePlanSize] = useState(state.size);
    const [description, setDescription] = useState(state.description);
    const [squareMeters, setSquareMeters] = useState(state.squareMeters);
    const [freePlan, setFreePlan] = useState(state.freePlan)
    const [showOnSpecs, setShowOnSpecs] = useState(true);
    const [displayIcon, setDisplayIcon] = useState(state.freePlan);
    const [sizes, setSizes] = useState([]);
    const [styles, setStyles] = useState([]);
    const [documentType, setDocumentType] = useState({ label: "PDF", value: "PDF" });
    const [housePlanDocument, setHousePlanDocument] = useState(null);
    const [housePlanDocumentId, setHousePlanDocumentId] = useState(null);
    const [deleteHousePlanDocumentDialog, setDeleteHousePlanDocumentDialog] = useState(false);
    const [acceptableDocumentType, setAcceptableDocumentType] = useState("application/pdf");
    const [blurred, setBlurred] = useState(false);
    const [designCategoryFormData, setDesignCategoryFormData] = useState(new FormData());
    const [housePlotSize, setHousePlotSize] = useState(null);
    const [length, setLength] = useState(0);
    const [width, setWidth] = useState(0);
    const [plotSizes, setPlotSizes] = useState([]);


    // Other variables
    const [submitted, setSubmitted] = useState(false);
    const [globalFilter, setGlobalFilter] = useState(null);
    const dt = useRef(null);
    const [isLoading, setIsLoading] = useState(false);
    const message = useRef();

    const documentTypes = SystemConstants.documentTypes;
    const displayIcons = SystemConstants.displayIcons;
    const uploadDocumentUrl = EndpointUploadDocument.replace(":id", state.id).replace(":documentType", documentType);
    const uploadDesignCategoryAttachmentUrl = EndpointSaveHousePlanDesignCategoryWithAttachment;

    function fetchHousePlanDesignCategories() {
        new HousePlanDesignCategoryService().getRecords(state.id, null, 0, -1)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setHousePlanDesignCategories(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    }

    function fetchHousePlanFeatures() {
        new HousePlanFeatureService().getRecords(state.id, null, 0, -1)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setHousePlanFeatures(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    }

    function fetchHousePlanDiscounts() {
        new HousePlanDiscountService().getRecords(state.id, null, 0, -1)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setHousePlanDiscounts(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    }

    function fetchDrawings() {
        new FileService().getRecords(state.id, null, 0, -1)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setHousePlanDrawings(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    }

    function fetchDocuments() {
        new FileService().getDocuments(state.id, null, 0, -1)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setHousePlanDocuments(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    }

    const fetchRecordsFromServer = () => {
        setIsLoading(true);

        fetchHousePlanDesignCategories();

        fetchHousePlanFeatures();

        fetchHousePlanDiscounts();

        fetchDrawings();

        fetchDocuments();

        // Load sizes
        new DesignCategoryService().getRecords(null, 0, -1)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setDesignCategories(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });

        // Load styles
        new FeatureService().getRecords(null, 0, -1)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setFeatures(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });

        // Load sizes
        new SizeService().getRecords(null, 0, -1)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setSizes(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });

        // Load styles
        new StyleService().getRecords(null, 0, -1)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setStyles(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });

        // Load plot sizes
        new PlotSizeService().getRecords(null, 0, -1)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setPlotSizes(value.records);
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });

    };


    useEffect(() => {
        fetchRecordsFromServer();
    }, []);


    const rowIndexTemplate = (rowData, props) => {
        let index = parseInt(props.rowIndex + 1, 10);
        return (
            <React.Fragment>
                <span>{index}</span>
            </React.Fragment>
        );
    };

    const openNewHousePlanFeature = () => {
        setHousePlanFeature(emptyHousePlanFeature);
        setSubmitted(false);
        setHousePlanFeatureDialog(true);
    };

    const hideHousePlanFeatureDialog = () => {
        setSubmitted(false);
        setHousePlanFeatureDialog(false);
    };

    const hideDeleteHousePlanFeatureDialog = () => {
        setDeleteHousePlanFeatureDialog(false);
    };

    const saveHousePlanFeature = () => {
        setSubmitted(true);
        setHousePlanFeatureDialog(false);
        setIsLoading(true);
        let data = {
            id: housePlanFeatureId,
            housePlan: state,
            feature: selectedFeature,
            numberOfItems: numberOfItems,
            showOnSpecs: showOnSpecs,
            displayIcon: displayIcon,
            position: position
        }

        new HousePlanFeatureService().saveRecord(data).then(async (response) => {
            setIsLoading(false);
            response.json().then((value) => {
                if (!response.ok) {
                    showServerError(response.status, value ? value : DefaultErrorMessage, message);
                } else {
                    setHousePlanFeatureId(null);
                    setSelectedFeature(null);
                    setNumberOfItems(null);
                    setPosition(0);
                    setShowOnSpecs(null);
                    setDisplayIcon(null);
                    fetchHousePlanFeatures();
                }
            });
        }).catch((error) => {
            console.log(error);
        });
    };

    const editHousePlanFeature = (record) => {
        setHousePlanFeatureId(record.id);
        setSelectedFeature(record.feature);
        setNumberOfItems(record.numberOfItems);
        setPosition(record.position);
        setShowOnSpecs(record.showOnSpecs);
        setDisplayIcon(record.displayIcon);
        setHousePlanFeatureDialog(true);
    };

    const confirmDeleteHousePlanFeature = (record) => {
        setHousePlanFeatureId(record.id);
        setHousePlanFeature(record);
        setDeleteHousePlanFeatureDialog(true);
    };

    const deleteHousePlanFeature = () => {
        setIsLoading(true);
        new HousePlanFeatureService().deleteRecord(housePlanFeatureId)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        hideDeleteHousePlanFeatureDialog();
                        fetchHousePlanFeatures();
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    };

    const actionHousePlanFeatureBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{ float: "right" }}>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editHousePlanFeature(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteHousePlanFeature(rowData)} />
            </div>
        );
    };

    const housePlanFeatureDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideHousePlanFeatureDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveHousePlanFeature} />
        </>
    );
    const deleteHousePlanFeatureDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteHousePlanFeatureDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteHousePlanFeature} />
        </>
    );
    //

    const openNewHousePlanDiscount = () => {
        setHousePlanDiscount(emptyHousePlanDiscount);
        setSubmitted(false);
        setHousePlanDiscountDialog(true);
    };

    const hideHousePlanDiscountDialog = () => {
        setSubmitted(false);
        setHousePlanDiscountDialog(false);
    };

    const hideDeleteHousePlanDiscountDialog = () => {
        setDeleteHousePlanDiscountDialog(false);
    };

    const saveHousePlanDiscount = () => {
        setSubmitted(true);
        setHousePlanDiscountDialog(false);
        setIsLoading(true);
        let data = {
            id: housePlanDiscountId,
            housePlan: state,
            percentage: percentageDiscount
        }

        new HousePlanDiscountService().saveRecord(data).then(async (response) => {
            setIsLoading(false);
            response.json().then((value) => {
                if (!response.ok) {
                    showServerError(response.status, value ? value : DefaultErrorMessage, message);
                } else {
                    setHousePlanDiscountId(null);
                    setSelectedDiscount(null);
                    setPercentageDiscount(null);
                    fetchHousePlanDiscounts();
                }
            });
        }).catch((error) => {
            console.log(error);
        });
    };

    const editHousePlanDiscount = (record) => {
        setHousePlanDiscountId(record.id);
        setPercentageDiscount(record.percentage);
        setHousePlanDiscountDialog(true);
    };

    const confirmDeleteHousePlanDiscount = (record) => {
        setHousePlanDiscountId(record.id);
        setHousePlanDiscount(record);
        setDeleteHousePlanDiscountDialog(true);
    };

    const deleteHousePlanDiscount = () => {
        setIsLoading(true);
        new HousePlanDiscountService().deleteRecord(housePlanDiscountId)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        hideDeleteHousePlanDiscountDialog();
                        fetchHousePlanDiscounts();
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    };

    const actionHousePlanDiscountBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{ float: "right" }}>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editHousePlanDiscount(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteHousePlanDiscount(rowData)} />
            </div>
        );
    };

    const housePlanDiscountDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideHousePlanDiscountDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveHousePlanDiscount} />
        </>
    );
    const deleteHousePlanDiscountDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteHousePlanDiscountDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteHousePlanDiscount} />
        </>
    );
    //


    const openNewHousePlanDesignCategory = () => {
        setHousePlanDesignCategory(emptyHousePlanDesignCategory);
        setSubmitted(false);
        setHousePlanDesignCategoryDialog(true);
    };

    const hideHousePlanDesignCategoryDialog = () => {
        setSubmitted(false);
        setHousePlanDesignCategoryDialog(false);
    };

    const hideDeleteHousePlanDesignCategoryDialog = () => {
        setDeleteHousePlanDesignCategoryDialog(false);
    };

    function handleUploadDesignCategory(event) {
        for (let file of event.files) {
            console.log("File data ", JSON.stringify(file));
            const formData = new FormData();
            formData.append('file', file);
            setDesignCategoryFormData(formData)
        }
    }

    const saveHousePlanDesignCategory = () => {
        setSubmitted(true);
        setHousePlanDesignCategoryDialog(false);
        setIsLoading(true);

        new HousePlanDesignCategoryService().saveRecordWithJsonAndFile(designCategoryFormData, housePlanDesignCategoryId, state.id, selectedDesignCategory?.id, pricePerSquareMeter, useAsDefault).then(async (response) => {
            setIsLoading(false);
            response.json().then((value) => {
                if (!response.ok) {
                    showServerError(response.status, value || DefaultErrorMessage, message);
                } else {
                    setHousePlanDesignCategoryId(null);
                    setSelectedDesignCategory(null);
                    setUseAsDefault(false);
                    setPricePerSquareMeter(0);
                    fetchHousePlanDesignCategories(); // Reload the page
                }
            });
        }).catch((error) => {
            console.log(error);
        });
    };

    const editHousePlanDesignCategory = (record) => {
        setHousePlanDesignCategoryId(record.id);
        setSelectedDesignCategory(record.designCategory);
        setUseAsDefault(record.useAsDefault);
        setPricePerSquareMeter(record.pricePerSquareMeter);
        setHousePlanDesignCategoryDialog(true);
    };

    const confirmDeleteHousePlanDesignCategory = (record) => {
        setHousePlanDesignCategoryId(record.id);
        setHousePlanDesignCategory(record);
        setDeleteHousePlanDesignCategoryDialog(true);
    };

    const deleteHousePlanDesignCategory = () => {
        setIsLoading(true);
        new HousePlanDesignCategoryService().deleteRecord(housePlanDesignCategoryId)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        hideDeleteHousePlanDesignCategoryDialog();
                        fetchHousePlanDesignCategories();
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    };
    // Edit house plan
    const editRecord = () => {
        setId(state.id);
        setName(state.name);
        setPlanId(state.planId);
        setDescription(state.description);
        setHousePlanStyle(state.style);
        setSquareMeters(state.squareMeters);
        setHousePlanSize(state.size);
        setFreePlan(state.freePlan);
        setWidth(state.width);
        setLength(state.length);
        setHousePlotSize(state.plotSize);
        setEditPlanDialog(true);
    };

    const hideHousePlanDialog = () => {
        setSubmitted(false);
        setEditPlanDialog(false);
    };

    const saveHousePlan = () => {
        setSubmitted(true);
        setEditPlanDialog(false);
        setIsLoading(true);
        let data = {
            id: id,
            planId: planId,
            name: name,
            freePlan: freePlan,
            description: description,
            size: housePlanSize,
            squareMeters: squareMeters,
            style: housePlanStyle,
            width: width,
            length: length,
            plotSize: housePlotSize
        }

        new HousePlanService().saveRecord(data).then(async (response) => {
            setIsLoading(false);
            response.json().then((value) => {
                if (!response.ok) {
                    showServerError(response.status, value ? value : DefaultErrorMessage, message);
                } else {
                    setId(value.id);
                    setName(value.name);
                    setPlanId(value.planId);
                    setDescription(value.description);
                    setHousePlanStyle(value.style);
                    setSquareMeters(value.squareMeters);
                    setHousePlanSize(value.size);
                    setFreePlan(value.freePlan);
                    setWidth(value.width);
                    setLength(value.length);
                    setHousePlotSize(value.plotSize);
                    fetchRecordsFromServer();
                }
            });
        }).catch((error) => {
            console.log(error);
        });
    };

    const recordDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideHousePlanDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveHousePlan} />
        </>
    );


    const actionHousePlanDesignCategoryBodyTemplate = (rowData) => {
        return (
            <div className="actions" style={{ float: "right" }}>
                <Button icon="pi pi-pencil" className="p-button-rounded p-button-success mr-2" onClick={() => editHousePlanDesignCategory(rowData)} />
                <Button icon="pi pi-trash" className="p-button-rounded p-button-warning mt-2" onClick={() => confirmDeleteHousePlanDesignCategory(rowData)} />
            </div>
        );
    };

    const housePlanDesignCategoryDialogFooter = (
        <>
            <Button label="Cancel" icon="pi pi-times" className="p-button-text" onClick={hideHousePlanDesignCategoryDialog} />
            <Button label="Save" icon="pi pi-check" className="p-button-text" onClick={saveHousePlanDesignCategory} />
        </>
    );
    const deleteHousePlanDesignCategoryDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteHousePlanDesignCategoryDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteHousePlanDesignCategory} />
        </>
    );

    const hideDeleteHousePlanDrawingDialog = () => {
        setDeleteHousePlanDrawingDialog(false);
    };

    const confirmDeleteHousePlanDrawing = (record) => {
        setHousePlanDrawingId(record.id);
        setHousePlanDrawing(record);
        setDeleteHousePlanDrawingDialog(true);
    };

    const deleteHousePlanDrawing = () => {
        setIsLoading(true);
        new FileService().deleteRecord(housePlanDrawingId)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        hideDeleteHousePlanDrawingDialog();
                        fetchDrawings();
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    };

    const deleteHousePlanDrawingDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteHousePlanDrawingDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteHousePlanDrawing} />
        </>
    );

    const hideDeleteHousePlanDocumentDialog = () => {
        setDeleteHousePlanDocumentDialog(false);
    };
    const confirmDeleteHousePlanDocument = (record) => {
        setHousePlanDocumentId(record.id);
        setHousePlanDocument(record);
        setDeleteHousePlanDocumentDialog(true);
    };

    const deleteHousePlanDocument = () => {
        setIsLoading(true);
        new FileService().deleteRecord(housePlanDocumentId)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        hideDeleteHousePlanDocumentDialog();
                        fetchDocuments();
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    };

    const deleteHousePlanDocumentDialogFooter = (
        <>
            <Button label="No" icon="pi pi-times" className="p-button-text" onClick={hideDeleteHousePlanDocumentDialog} />
            <Button label="Yes" icon="pi pi-check" className="p-button-text" onClick={deleteHousePlanDocument} />
        </>
    );

    const designCategoryBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Design Category</span>
                {rowData.designCategory.name}
            </>
        );
    };
    const priceBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price</span>
                {ThousandsSeparatorNoCurrency(rowData.price)}
            </>
        );
    };
    const featureBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Feature</span>
                {rowData.feature.name}
            </>
        );
    };
    const squareMetersBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Square Meters</span>
                {rowData.squareMeters}
            </>
        );
    };

    const numberOfItemsBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Number Of Items</span>
                {rowData.numberOfItems}
            </>
        );
    };
    const positionBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Display Position</span>
                {rowData.position}
            </>
        );
    };

    const showOnSpecsBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Show On Specs</span>
                {formatBooleanFields(rowData.showOnSpecs)}
            </>
        );
    };

    const percentageDiscountBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Percentage</span>
                {rowData.percentage}
            </>
        );
    };

    const activeBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Active</span>
                {formatBooleanFields(rowData.active)}
            </>
        );
    };

    const displayIconBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Display Icon</span>
                {rowData.displayIcon}
            </>
        );
    };

    const pricePerSquareMeterBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Price Per Square Meters</span>
                {rowData.pricePerSquareMeter}
            </>
        );
    };

    const useAsDefaultBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Use As Default</span>
                {formatBooleanFields(rowData.useAsDefault)}
            </>
        );
    };

    const hasAttachmentBodyTemplate = (rowData) => {
        return (
            <>
                <span className="p-column-title">Has Attachment</span>
                {formatBooleanFields(rowData.designCategoryAttachmentId != null)}
            </>
        );
    };
    const itemTemplate = (product, layout, index) => {
        if (!product) {
            return;
        }
        if (layout === 'list') return gridItem(product);
        else if (layout === 'grid') return gridItem(product);
    };

    const gridItem = (item) => {
        const imageUrl = `${EndpointGetFileBytes}`.replace(":id", item.id);
        return (
            <div className="col-12 sm:col-6 lg:col-12 xl:col-4 p-2" key={item.id}>
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="flex flex-column align-items-center gap-3" style={{ padding: "0.5rem;" }}> {/* Reduced padding here */}
                        <img className="w-9 shadow-2 border-round"
                            src={imageUrl}
                            alt={item.name} />
                    </div>
                    <div className="flex align-items-center justify-content-between">
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning mt-2 ml-auto"
                            onClick={() => confirmDeleteHousePlanDrawing(item)}></Button>
                    </div>
                </div>
            </div>
        );
    };

    const itemTemplateDocument = (product, layout, index) => {
        if (!product) {
            return;
        }
        if (layout === 'list') return gridItem(product);
        else if (layout === 'grid') return gridItemDocument(product);
    };

    const gridItemDocument = (item) => {
        const documentUrl = EndpointGetFileBytes.replace(":id", item.id);
        const fileExtension = item.fileName ? item.fileName.split('.').pop().toLowerCase() : '';

        const renderDocumentIcon = () => {
            switch (fileExtension) {
                case 'pdf':
                    return <FontAwesomeIcon icon={faFilePdf} size="4x" color="red" />;
                case 'dwg': // Assuming CAD files have the extension .dwg
                case 'dxf': // Another common CAD file extension
                    return <FontAwesomeIcon icon={faFile} size="4x" color="blue" />;
                default:
                    return <FontAwesomeIcon icon={faFile} size="4x" color="gray" />;
            }
        };
        return (
            <div className="col-12 sm:col-6 lg:col-12 xl:col-4 p-2" key={item.id}>
                <div className="p-4 border-1 surface-border surface-card border-round">
                    <div className="flex flex-column align-items-center gap-3" style={{ padding: "0.5rem" }}>
                        <a href={documentUrl} target="_blank" rel="noopener noreferrer">
                            {renderDocumentIcon()}
                        </a>
                        <p>{item.uploadedFileName ? item.uploadedFileName : item.fileName}</p>
                    </div>
                    <div className="flex align-items-center justify-content-between">
                        <Button
                            icon="pi pi-trash"
                            className="p-button-rounded p-button-warning mt-2 ml-auto"
                            onClick={() => confirmDeleteHousePlanDocument(item)}></Button>
                    </div>
                </div>
            </div>
        );
    };

    function handleUpload(event) {
        for (let file of event.files) {
            const formData = new FormData();
            formData.append('file', file);
            setIsLoading(true);
            new FileService().saveRecord(formData, state.id, blurred)
                .then(async (response) => {
                    setIsLoading(false);
                    response.json().then((value) => {
                        if (response.ok) {
                            fetchDrawings();
                        } else {
                            showServerError(response.status, value ? value : DefaultErrorMessage, message);
                        }
                    });
                }).catch((error) => {
                    console.log(error);
                });
        }
    };

    function handleUploadDocument(event) {
        console.log("File content ", JSON.stringify(event))
        for (let file of event.files) {
            const formData = new FormData();
            formData.append('file', file);
            setIsLoading(true);
            new FileService().uploadDocument(formData, state.id, documentType)
                .then(async (response) => {
                    setIsLoading(false);
                    response.json().then((value) => {
                        if (response.ok) {
                            fetchDocuments();
                        } else {
                            showServerError(response.status, value ? value : DefaultErrorMessage, message);
                        }
                    });
                }).catch((error) => {
                    console.log(error);
                });
        }
    };

    const approveHousePlan = () => {
        setIsLoading(true);
        new HousePlanService().approveHousePlan(state.id)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setId(value.id);
                        setName(value.name);
                        setPlanId(value.planId);
                        setDescription(value.description);
                        setHousePlanStyle(value.style);
                        setSquareMeters(value.squareMeters);
                        setHousePlanSize(value.size);
                        setFreePlan(value.freePlan);
                        setHousePlanStatus(value.housePlanStatus);
                        fetchRecordsFromServer();
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    };
    const pullDownHousePlan = () => {
        setIsLoading(true);
        new HousePlanService().pullDownHousePlan(state.id)
            .then(async (response) => {
                setIsLoading(false);
                response.json().then((value) => {
                    if (response.ok) {
                        setId(value.id);
                        setName(value.name);
                        setPlanId(value.planId);
                        setDescription(value.description);
                        setHousePlanStyle(value.style);
                        setSquareMeters(value.squareMeters);
                        setHousePlanSize(value.size);
                        setFreePlan(value.freePlan);
                        setHousePlanStatus(value.housePlanStatus);
                        fetchRecordsFromServer();
                    } else {
                        showServerError(response.status, value ? value : DefaultErrorMessage, message);
                    }
                });
            }).catch((error) => {
                console.log(error);
            });
    };


    return (
        <div className="grid crud-demo">
            <div className="col-12">
                <div className="card">
                    <Messages ref={message} />

                    <TabView activeIndex={activeIndex} onTabChange={handleTabChange}>
                        <TabPanel header="General Information">
                            <div className="grid p-fluid">
                                <div className="col-12 md:col-12">
                                    <div className="surface-section">
                                        <ul className="list-none p-0 m-0" style={{ textDecoration: "none !important" }}>
                                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap no-border">
                                                <div className="font-medium text-900 w-full md:w-10 md:flex-order-0 flex-order-1">{name} - {planId}</div>
                                                <div className="w-6 md:w-2 flex justify-content-end">
                                                    <Button icon="pi pi-pencil"
                                                        className="p-button-text"
                                                        onClick={() => editRecord()} />
                                                </div>
                                            </li>
                                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap no-border">
                                                <div className="text-900 w-6 md:w-2 font-medium">Style</div>
                                                <div className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">{housePlanStyle.name}</div>
                                            </li>
                                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap no-border">
                                                <div className="text-900 w-6 md:w-2 font-medium">Size</div>
                                                <div className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">{housePlanSize.name}</div>
                                            </li>
                                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap no-border">
                                                <div className="text-900 w-6 md:w-2 font-medium">Square Meters</div>
                                                <div className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">{squareMeters}</div>
                                            </li>
                                            <li className="flex align-items-center py-3 px-2 border-top-1 surface-border flex-wrap no-border">
                                                <div className="text-900 w-6 md:w-2 font-medium">Free Pan</div>
                                                <div className="text-900 w-full md:w-10 md:flex-order-0 flex-order-1">{formatBooleanFields(freePlan)}</div>
                                            </li>
                                            <li className="flex align-items-center py-3 px-2 border-top-1 border-bottom-1 surface-border flex-wrap no-border">
                                                <div className="font-medium text-900 w-6 md:w-12">Plan Description</div>
                                                <div className="text-900 w-full md:w-12 md:flex-order-0 flex-order-1 line-height-3"
                                                    style={{ marginTop: "1em" }}
                                                    dangerouslySetInnerHTML={{ __html: DOMPurify.sanitize(description) }} />
                                            </li>
                                        </ul>
                                    </div>

                                    <Dialog
                                        visible={editPlanDialog}
                                        style={{ width: '700px' }}
                                        header="House Plan Details" modal className="p-fluid"
                                        footer={recordDialogFooter}
                                        onHide={hideHousePlanDialog}>
                                        <div className="grid p-fluid">
                                            <div className="col-12 md:col-12">
                                                <div className="field">
                                                    <label htmlFor="name">Name</label>
                                                    <InputText id="name" value={name}
                                                        onChange={(e) => setName(e.target.value || '')}
                                                        required autoFocus className={classNames({ 'p-invalid': submitted && !name })} />
                                                    {submitted && !name && <small className="p-invalid">Name is required.</small>}
                                                </div>
                                            </div>

                                            <div className="col-12 md:col-6">
                                                <div className="field">
                                                    <label htmlFor="size">Size</label>
                                                    <Dropdown
                                                        id="size"
                                                        value={housePlanSize}
                                                        onChange={(e) => setHousePlanSize(e.value)}
                                                        options={sizes}
                                                        optionLabel="name"
                                                        placeholder="Select a size" />
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <div className="field">
                                                    <label htmlFor="templateType">Style</label>
                                                    <Dropdown
                                                        id="templateType"
                                                        value={housePlanStyle}
                                                        onChange={(e) => setHousePlanStyle(e.value)}
                                                        options={styles}
                                                        optionLabel="name"
                                                        placeholder="Select a Style" />
                                                </div>
                                            </div>

                                            <div className="col-12 md:col-4">
                                                <div className="field">
                                                    <label htmlFor="templateType">Plot Size</label>
                                                    <Dropdown
                                                        id="templateType"
                                                        value={housePlotSize}
                                                        onChange={(e) => {
                                                            console.log("Plot size ", JSON.stringify(e.value))
                                                            setHousePlotSize(e.value)
                                                        }}
                                                        options={plotSizes}
                                                        optionLabel="name"
                                                        placeholder="Select a plot size" />
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-4">
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="width">Width</label>
                                                        <InputNumber id="width" value={width} onValueChange={(e) => setWidth(e.value || 0)} required autoFocus className={classNames({ 'p-invalid': submitted && (!width || width < 0) })} />
                                                        {submitted && (!width || width < 0) && <small className="p-invalid">Width is required.</small>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-4">
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="length">Length</label>
                                                        <InputNumber id="length" value={length} onValueChange={(e) => setLength(e.value || 0)} required autoFocus className={classNames({ 'p-invalid': submitted && (!length || length <= 0) })} />
                                                        {submitted && (!length || length <= 0) && <small className="p-invalid">Length is required.</small>}
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 md:col-6">
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="squareMeters">Square Meters</label>
                                                        <InputNumber id="squareMeters" value={squareMeters} onValueChange={(e) => setSquareMeters(e.value || 0)} required autoFocus className={classNames({ 'p-invalid': submitted && (!squareMeters || squareMeters <= 0) })} />
                                                        {submitted && (!squareMeters || squareMeters <= 0) && <small className="p-invalid">Size is required.</small>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-6">
                                                <div className="formgrid grid">
                                                    <div className="field col" style={{ ...spreadElements }}>
                                                        <label htmlFor="userAsDefault" style={{ paddingRight: "0.5em" }}> Make plan free</label>
                                                        <InputSwitch
                                                            id="userAsDefault"
                                                            checked={freePlan}
                                                            onChange={(e) => setFreePlan(e.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-12">
                                                <div className="field">
                                                    <label htmlFor="description">Description</label>
                                                    <Editor id="description" value={description}
                                                        onTextChange={(e) => setDescription(e.htmlValue)}
                                                        style={{ height: '180px' }} />
                                                    {submitted && !description && <small className="p-invalid">Description is required.</small>}
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog>

                                    <div className="actions" style={{ float: "right" }}>
                                        {housePlanStatus !== 'APPROVED' && (<Button icon="pi pi-check" className="p-button-rounded p-button-success mr-2" onClick={() => approveHousePlan()} />)}
                                        {housePlanStatus === 'APPROVED' && (<Button icon="pi pi-caret-down" className="p-button-rounded p-button-warning mt-2" onClick={() => pullDownHousePlan()} />)}
                                    </div>

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Design Categories">
                            <div className="grid p-fluid">
                                <div className="col-12 md:col-12">
                                    <SearchFilter
                                        style={{ float: "right" }}
                                        showInputSearchBar={false}
                                        showAddNew={true}
                                        showResetFilter={false}
                                        onAddNew={openNewHousePlanDesignCategory}
                                    />
                                </div>
                                <div className="col-12 md:col-12">
                                    <DataTable
                                        ref={dt}
                                        value={housePlanDesignCategories}
                                        selectionMode="single" selection={selectedHousePlanDesignCategory}
                                        onSelectionChange={(e) => setSelectedHousePlanDesignCategory(e.value)}
                                        rows={20}
                                        rowsPerPageOptions={[20, 50, 100]}
                                        globalFilter={globalFilter}
                                        dataKey="id"
                                        paginator={false}
                                        className="datatable-responsive"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                                        paginatorPosition="bottom"
                                        paginatorAlwaysVisible="false"
                                        emptyMessage="No record found."
                                        loading={isLoading}
                                        reflow="true"
                                        responsiveLayout="scroll">
                                        <Column field="Index" header="#" style={{ width: "6rem" }} body={rowIndexTemplate}></Column>
                                        <Column field="designCategory" header="Design Category" body={designCategoryBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                                        <Column field="squareMeters" header="Square Meters" body={squareMetersBodyTemplate}></Column>
                                        <Column field="pricePerSquareMeter" header="Price Per Square Meter" body={pricePerSquareMeterBodyTemplate}></Column>
                                        <Column field="price" header="Price" body={priceBodyTemplate}></Column>
                                        <Column field="useAsDefault" header="Use As Default" body={useAsDefaultBodyTemplate}></Column>
                                        <Column field="hasAttachment" header="Has Design Attachment" body={hasAttachmentBodyTemplate}></Column>
                                        <Column body={actionHousePlanDesignCategoryBodyTemplate}></Column>
                                    </DataTable>

                                    <Dialog visible={housePlanDesignCategoryDialog} style={{ width: '650px' }} header="House Plan Design Category Details" modal className="p-fluid" footer={housePlanDesignCategoryDialogFooter} onHide={hideHousePlanDesignCategoryDialog}>
                                        <div className="grid p-fluid">

                                            <div className="col-12 md:col-12">
                                                <div className="field">
                                                    <label htmlFor="size">Design Category</label>
                                                    <Dropdown
                                                        id="designCategory"
                                                        value={selectedDesignCategory}
                                                        onChange={(e) => {
                                                            setSelectedDesignCategory(e.value);
                                                            console.log("Selected category " + JSON.stringify(e.value));
                                                            if (e.value?.useAsDefaultPriceForPlan === true) {
                                                                setPricePerSquareMeter(e.value?.defaultPrice);
                                                                setUseAsDefault(e.value?.useAsDefaultPriceForPlan);
                                                            } else {
                                                                setPricePerSquareMeter(0);
                                                                setUseAsDefault(false);
                                                            }
                                                        }}
                                                        options={designCategories}
                                                        optionLabel="name"
                                                        placeholder="Select a Category" />
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-12">
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="pricePerSquareMeter">Price Per Square Meters</label>
                                                        <InputNumber id="pricePerSquareMeter" value={pricePerSquareMeter}
                                                            onValueChange={(e) => setPricePerSquareMeter(e.value || 0)}
                                                            required autoFocus className={classNames({ 'p-invalid': submitted && (!pricePerSquareMeter || pricePerSquareMeter <= 0) })} />
                                                        {submitted && (!pricePerSquareMeter || pricePerSquareMeter <= 0) && <small className="p-invalid">Price Per Square Meter is required.</small>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-12">
                                                <div className="formgrid grid">
                                                    <div className="field col" style={{ ...spreadElements }}>
                                                        <label htmlFor="userAsDefault" style={{ paddingRight: "0.5em" }}> Use as default house plan price</label>
                                                        <InputSwitch
                                                            id="userAsDefault"
                                                            checked={useAsDefault}
                                                            onChange={(e) => setUseAsDefault(e.value)} />
                                                    </div>
                                                </div>
                                            </div>

                                            <div className="col-12 md:col-12">
                                                <div className="field">
                                                    <label htmlFor="documentType">Document Type</label>
                                                    <Dropdown
                                                        id="documentType"
                                                        value={documentType}
                                                        onChange={(e) => {
                                                            setDocumentType(e.value);
                                                            if (e.value === 'CAD')
                                                                setAcceptableDocumentType(".dwg,.dxf");
                                                            else
                                                                setAcceptableDocumentType("application/pdf");
                                                        }}
                                                        options={documentTypes}
                                                        optionLabel="label"
                                                        placeholder="Select a Type" />
                                                </div>
                                            </div>

                                            {documentType && (
                                                <div className="col-12 md:col-12">
                                                    <div className="formgrid grid">
                                                        <div className="field col" style={{ ...spreadElements }}>
                                                            <FileUpload style={{ float: "right", width: "100%" }} className="p-button-success"
                                                                multiple
                                                                name="file"
                                                                auto="false"
                                                                accept={acceptableDocumentType}                        
                                                                maxFileSize={150000000}
                                                                chooseLabel="Browse Design Category Image"
                                                                onSelect={handleUploadDesignCategory}
                                                                emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />

                                                        </div>
                                                    </div>
                                                </div>)}
                                        </div>
                                    </Dialog>

                                    <Dialog visible={deleteHousePlanDesignCategoryDialog} style={{ width: '450px' }} header="Confirm" modal
                                        footer={deleteHousePlanDesignCategoryDialogFooter} onHide={hideDeleteHousePlanDesignCategoryDialog}>
                                        <div className="flex align-items-center justify-content-center">
                                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                            {housePlanDesignCategory && (
                                                <span>Are you sure you want to delete this record?</span>
                                            )}
                                        </div>
                                    </Dialog>

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Features">
                            <div className="grid p-fluid">
                                <div className="col-12 md:col-12">
                                    <SearchFilter
                                        style={{ float: "right" }}
                                        showInputSearchBar={false}
                                        showAddNew={true}
                                        showResetFilter={false}
                                        onAddNew={openNewHousePlanFeature}
                                    /></div>

                                <div className="col-12 md:col-12">
                                    <DataTable
                                        ref={dt}
                                        value={housePlanFeatures}
                                        selectionMode="single" selection={selectedHousePlanFeature}
                                        onSelectionChange={(e) => setSelectedHousePlanFeature(e.value)}
                                        rows={20}
                                        rowsPerPageOptions={[20, 50, 100]}
                                        globalFilter={globalFilter}
                                        dataKey="id"
                                        paginator={false}
                                        className="datatable-responsive"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                                        paginatorPosition="bottom"
                                        paginatorAlwaysVisible="false"
                                        emptyMessage="No record found."
                                        loading={isLoading}
                                        reflow="true"
                                        responsiveLayout="scroll">
                                        <Column field="Index" header="#" style={{ width: "6rem" }} body={rowIndexTemplate}></Column>
                                        <Column field="feature" header="Feature" body={featureBodyTemplate} headerStyle={{ width: '14%', minWidth: '8rem' }}></Column>
                                        <Column field="numberOfItems" header="Number Of Items" body={numberOfItemsBodyTemplate}></Column>
                                        <Column field="position" header="Display Position" body={positionBodyTemplate}></Column>
                                        <Column field="showOnSpecs" header="Show On Specs" body={showOnSpecsBodyTemplate}></Column>
                                        <Column field="displayIcon" header="Display Icon" body={displayIconBodyTemplate}></Column>
                                        <Column body={actionHousePlanFeatureBodyTemplate}></Column>
                                    </DataTable>

                                    <Dialog visible={housePlanFeatureDialog} style={{ width: '450px' }} header="House Plan Feature Details" modal className="p-fluid" footer={housePlanFeatureDialogFooter} onHide={hideHousePlanFeatureDialog}>
                                        <div className="grid p-fluid">

                                            <div className="col-12 md:col-12">
                                                <div className="field">
                                                    <label htmlFor="feature">Feature</label>
                                                    <Dropdown
                                                        id="feature"
                                                        value={selectedFeature}
                                                        onChange={(e) => {
                                                            setSelectedFeature(e.value);
                                                            console.log("Selected Feature " + JSON.stringify(e.value));
                                                            setPosition(e.value?.position);
                                                        }}
                                                        options={features}
                                                        optionLabel="name"
                                                        placeholder="Select a feature" />
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-12">
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="numberOfItems">Number of Items</label>
                                                        <InputNumber id="numberOfItems" value={numberOfItems} onValueChange={(e) => setNumberOfItems(e.value || 0)}
                                                            required autoFocus
                                                            className={classNames({ 'p-invalid': submitted && (!numberOfItems || numberOfItems <= 0) })} />
                                                        {submitted && (!numberOfItems || numberOfItems <= 0) && <small className="p-invalid">Number of items is required.</small>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-12">
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="position">Display Position</label>
                                                        <InputNumber id="position" value={position} onValueChange={(e) => setPosition(e.value || 0)}
                                                            required autoFocus
                                                            className={classNames({ 'p-invalid': submitted && (!position || position <= 0) })} />
                                                        {submitted && (!position || position <= 0) && <small className="p-invalid">Display position is required.</small>}
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-12 md:col-12">
                                                <div className="formgrid grid">
                                                    <div className="field col" style={{ ...spreadElements }}>
                                                        <label htmlFor="showOnSpecs" style={{ paddingRight: "0.5em" }}> Show Under Plan Description</label>
                                                        <InputSwitch
                                                            id="showOnSpecs"
                                                            checked={showOnSpecs}
                                                            onChange={(e) => setShowOnSpecs(e.value)} />
                                                    </div>
                                                </div>
                                            </div>
                                            {showOnSpecs && (
                                                <div className="col-12 md:col-12">
                                                    <div className="field">
                                                        <label htmlFor="feature">Display Icon</label>
                                                        <Dropdown
                                                            id="displayIcon"
                                                            value={displayIcon}
                                                            onChange={(e) => setDisplayIcon(e.value)}
                                                            options={displayIcons}
                                                            optionLabel="label"
                                                            placeholder="Select a display icon" />
                                                    </div>
                                                </div>
                                            )}


                                        </div>
                                    </Dialog>

                                    <Dialog visible={deleteHousePlanFeatureDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteHousePlanFeatureDialogFooter} onHide={hideDeleteHousePlanFeatureDialog}>
                                        <div className="flex align-items-center justify-content-center">
                                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                            {housePlanFeature && (
                                                <span>Are you sure you want to delete this record?</span>
                                            )}
                                        </div>
                                    </Dialog>

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Discounts">
                            <div className="grid p-fluid">
                                <div className="col-12 md:col-12">
                                    <SearchFilter
                                        style={{ float: "right" }}
                                        showInputSearchBar={false}
                                        showAddNew={true}
                                        showResetFilter={false}
                                        onAddNew={openNewHousePlanDiscount}
                                    /></div>

                                <div className="col-12 md:col-12">
                                    <DataTable
                                        ref={dt}
                                        value={housePlanDiscounts}
                                        selectionMode="single" selection={selectedHousePlanDiscount}
                                        onSelectionChange={(e) => setSelectedHousePlanDiscount(e.value)}
                                        rows={20}
                                        rowsPerPageOptions={[20, 50, 100]}
                                        globalFilter={globalFilter}
                                        dataKey="id"
                                        paginator={false}
                                        className="datatable-responsive"
                                        paginatorTemplate="FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink CurrentPageReport RowsPerPageDropdown"
                                        currentPageReportTemplate="Showing {first} to {last} of {totalRecords} records"
                                        paginatorPosition="bottom"
                                        paginatorAlwaysVisible="false"
                                        emptyMessage="No record found."
                                        loading={isLoading}
                                        reflow="true"
                                        responsiveLayout="scroll">
                                        <Column field="Index" header="#" style={{ width: "6rem" }} body={rowIndexTemplate}></Column>
                                        <Column field="percentage" header="Percentage" body={percentageDiscountBodyTemplate}></Column>
                                        <Column field="active" header="Active" body={activeBodyTemplate}></Column>
                                        <Column body={actionHousePlanDiscountBodyTemplate}></Column>
                                    </DataTable>

                                    <Dialog visible={housePlanDiscountDialog} style={{ width: '450px' }} header="House Plan Discount Details" modal className="p-fluid" footer={housePlanDiscountDialogFooter} onHide={hideHousePlanDiscountDialog}>
                                        <div className="grid p-fluid">

                                            <div className="col-12 md:col-12">
                                                <div className="formgrid grid">
                                                    <div className="field col">
                                                        <label htmlFor="percentageDiscount">Percentage Discount</label>
                                                        <InputNumber id="percentageDiscount" value={percentageDiscount} onValueChange={(e) => setPercentageDiscount(e.value || 0)}
                                                            required autoFocus
                                                            className={classNames({ 'p-invalid': submitted && (!percentageDiscount || percentageDiscount <= 0) })} />
                                                        {submitted && (!percentageDiscount || percentageDiscount <= 0) && <small className="p-invalid">Percentage discount is required.</small>}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Dialog>

                                    <Dialog visible={deleteHousePlanDiscountDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteHousePlanDiscountDialogFooter} onHide={hideDeleteHousePlanDiscountDialog}>
                                        <div className="flex align-items-center justify-content-center">
                                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                            {housePlanDiscount && (
                                                <span>Are you sure you want to delete this record?</span>
                                            )}
                                        </div>
                                    </Dialog>

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Images">
                            <div className="grid p-fluid">
                                <div className="col-12 md:col-12">
                                    <div className="formgrid grid">
                                        <div className="field col" style={{ ...spreadElements }}>
                                            <label htmlFor="blurred" style={{ paddingRight: "0.5em" }}> Make image blurred</label>
                                            <InputSwitch
                                                id="blurred"
                                                checked={blurred}
                                                onChange={(e) => setBlurred(e.value)} />
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 md:col-12">
                                    <FileUpload style={{ float: "right", width: "100%" }} className="p-button-success"
                                        multiple
                                        name="file"
                                        url={uploadImageUrl}
                                        accept="image/*"
                                        maxFileSize={150000000}
                                        chooseLabel="Browse Image"
                                        onUpload={handleUpload}
                                        emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                                </div>

                                <div className="col-12 md:col-12">
                                    <DataView
                                        value={housePlanDrawings}
                                        itemTemplate={itemTemplate}
                                        layout={layout} />

                                    <Dialog visible={deleteHousePlanDrawingDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteHousePlanDrawingDialogFooter} onHide={hideDeleteHousePlanDrawingDialog}>
                                        <div className="flex align-items-center justify-content-center">
                                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                            {housePlanDrawing && (
                                                <span>Are you sure you want to delete this record?</span>
                                            )}
                                        </div>
                                    </Dialog>

                                </div>
                            </div>
                        </TabPanel>
                        <TabPanel header="Documents">
                            <div className="grid p-fluid">
                                <div className="col-12 md:col-4">
                                    <div className="field">
                                        <label htmlFor="documentType">Document Type</label>
                                        <Dropdown
                                            id="documentType"
                                            value={documentType}
                                            onChange={(e) => {
                                                setDocumentType(e.value);
                                                if (e.value === 'CAD')
                                                    setAcceptableDocumentType(".dwg,.dxf");
                                                else
                                                    setAcceptableDocumentType("application/pdf");
                                            }}
                                            options={documentTypes}
                                            optionLabel="label"
                                            placeholder="Select a Type" />
                                    </div>
                                </div>
                                {documentType && (<div className="col-12 md:col-8">
                                    <FileUpload style={{ float: "right", width: "100%" }} className="p-button-success"
                                        multiple
                                        name="file"
                                        url={uploadDocumentUrl}
                                        accept={acceptableDocumentType}
                                        maxFileSize={150000000}
                                        chooseLabel="Browse Document"
                                        onUpload={handleUploadDocument}
                                        emptyTemplate={<p className="m-0">Drag and drop files to here to upload.</p>} />
                                </div>)}

                                <div className="col-12 md:col-12">
                                    <DataView
                                        value={housePlanDocuments}
                                        itemTemplate={itemTemplateDocument}
                                        layout={layout} />

                                    <Dialog visible={deleteHousePlanDocumentDialog} style={{ width: '450px' }} header="Confirm" modal footer={deleteHousePlanDocumentDialogFooter} onHide={hideDeleteHousePlanDocumentDialog}>
                                        <div className="flex align-items-center justify-content-center">
                                            <i className="pi pi-exclamation-triangle mr-3" style={{ fontSize: '2rem' }} />
                                            {housePlanDocument && (
                                                <span>Are you sure you want to delete this record?</span>
                                            )}
                                        </div>
                                    </Dialog>

                                </div>
                            </div>
                        </TabPanel>

                    </TabView>

                </div>
            </div>
        </div>
    );
};

export default ViewManageHousePlan;
