// Read base URL from environment variable or default to localhost
const baseUrl = "https://alnactest.pahappa.net/engine/api";
// const baseUrl = "http://localhost:8090/engine/api";


export const EndpointLogin = `${baseUrl}/v1/admin/login`;
export const EndpointGetClients = `${baseUrl}/v1/admin/users?`;

export const EndpointUpdateProfile = `${baseUrl}/v1/user/update/profile`;
export const EndpointUpdateEmail = `${baseUrl}/v1/user/update/email`;
export const EndpointUpdatePassword = `${baseUrl}/v1/user/update/password`;
export const EndpointGetProfile = `${baseUrl}/v1/user/profile`;
export const EndpointSaveUser = `${baseUrl}/v1/user/save`
export const EndpointSaveRole = `${baseUrl}/v1/role`;
export const EndpointGetRoles = `${baseUrl}/v1/roles?`;
export const EndpointDeleteUser = `${baseUrl}/v1/user/:id/delete`;
export const EndpointDeleteRole = `${baseUrl}/v1/role/:id/delete`;
export const EndpointGetPermissions = `${baseUrl}/v1/permissions?`;
export const EndpointSaveLookup = `${baseUrl}/v1/lookup`;
export const EndpointGetLookups = `${baseUrl}/public/v1/lookups?`;
export const EndpointInitiatePasswordReset = `${baseUrl}/public/v1/passwordreset`;
export const EndpointChangePassword = `${baseUrl}/public/v1/changepassword`;
export const EndpointSaveSize = `${baseUrl}/v1/size`;
export const EndpointDeleteSize = `${baseUrl}/v1/size/:id/delete`;
export const EndpointGetSizes = `${baseUrl}/public/v1/sizes?`;
export const EndpointSavePlotSize = `${baseUrl}/v1/plot/size`;
export const EndpointDeletePlotSize = `${baseUrl}/v1/plot/size/:id/delete`;
export const EndpointGetPlotSizes = `${baseUrl}/public/v1/plot/sizes?`;
export const EndpointSaveStyle = `${baseUrl}/v1/style`;
export const EndpointDeleteStyle = `${baseUrl}/v1/style/:id/delete`;
export const EndpointGetStyles = `${baseUrl}/public/v1/styles?`;
export const EndpointSaveFeature = `${baseUrl}/v1/feature`;
export const EndpointDeleteFeature = `${baseUrl}/v1/feature/:id/delete`;
export const EndpointGetFeatures = `${baseUrl}/public/v1/features?`;
export const EndpointSaveFAQ = `${baseUrl}/v1/faq`;
export const EndpointDeleteFAQ = `${baseUrl}/v1/faq/:id/delete`;
export const EndpointGetFAQs = `${baseUrl}/public/v1/faqs?`;
export const EndpointSaveBlog = `${baseUrl}/v1/blog`;
export const EndpointDeleteBlog = `${baseUrl}/v1/blog/:id/delete`;
export const EndpointGetBlogs = `${baseUrl}/public/v1/blogs?`;
export const EndpointSaveNewsLetter = `${baseUrl}/v1/news/letter`;
export const EndpointDeleteNewsLetter = `${baseUrl}/v1/news/letter/:id/delete`;
export const EndpointGetNewsLetters = `${baseUrl}/v1/news/letters?`;
export const EndpointSaveNewsLetterSubscriber = `${baseUrl}/v1/news/letter/subscription`;
export const EndpointDeleteNewsLetterSubscriber = `${baseUrl}/v1/news/letter/subscription/:id/delete`;
export const EndpointGetNewsLetterSubscribers = `${baseUrl}/v1/news/letter/subscriptions?`;
export const EndpointCostCalculator = `${baseUrl}/v1/costcalculator`;
export const EndpointSaveCostCalculatorCategory = `${baseUrl}/v1/costcalculator/category`;
export const EndpointDeleteCostCalculatorCategory = `${baseUrl}/v1/costcalculator/category/:id/delete`;
export const EndpointGetCostCalculatorCategories = `${baseUrl}/public/v1/costcalculator/categories?`;
export const EndpointSaveCostCalculatorItem = `${baseUrl}/v1/costcalculator/item`;
export const EndpointDeleteCostCalculatorItem = `${baseUrl}/v1/costcalculator/item/:id/delete`;
export const EndpointGetCostCalculatorItems = `${baseUrl}/public/v1/costcalculator/items?`;
export const EndpointSaveDesignCategory = `${baseUrl}/v1/design/category`;
export const EndpointDeleteDesignCategory = `${baseUrl}/v1/design/category/:id/delete`;
export const EndpointGetDesignCategories = `${baseUrl}/public/v1/design/categories?`;
export const EndpointSaveHousePlan = `${baseUrl}/v1/house/plan`;
export const EndpointDeleteHousePlan = `${baseUrl}/v1/house/plan/:id/delete`;
export const EndpointApproveHousePlan = `${baseUrl}/v1/house/plan/:id/approve`;
export const EndpointPullDownHousePlan = `${baseUrl}/v1/house/plan/:id/pulldown`;
export const EndpointGetHousePlans = `${baseUrl}/v1/house/plans?`;
export const EndpointSaveInformationPage = `${baseUrl}/v1/information/page`;
export const EndpointDeleteInformationPage = `${baseUrl}/v1/information/page/:id/delete`;
export const EndpointGetInformationPages = `${baseUrl}/public/v1/information/pages?`;
export const EndpointSaveAccountType = `${baseUrl}/v1/account/type`;
export const EndpointDeleteAccountType = `${baseUrl}/v1/account/type/:id/delete`;
export const EndpointGetAccountTypes = `${baseUrl}/public/v1/account/types?`;
export const EndpointSaveMessageTemplate = `${baseUrl}/v1/messagetemplate`;
export const EndpointDeleteMessageTemplate = `${baseUrl}/v1/messagetemplate/:id/delete`;
export const EndpointGetMessageTemplates = `${baseUrl}/v1/messagetemplates?`;
export const EndpointSaveHousePlanDesignCategory = `${baseUrl}/v1/house/plan/category`;
export const EndpointSaveHousePlanDesignCategoryWithAttachment= `${baseUrl}/public/v1/house/plan/category/attachment?`;
export const EndpointDeleteHousePlanDesignCategory = `${baseUrl}/v1/house/plan/category/:id/delete`;
export const EndpointGetHousePlanDesignCategories = `${baseUrl}/public/v1/house/plan/:id/categories?`;
export const EndpointSaveHousePlanFeature = `${baseUrl}/v1/house/plan/feature`;
export const EndpointDeleteHousePlanFeature = `${baseUrl}/v1/house/plan/feature/:id/delete`;
export const EndpointGetHousePlanFeatures = `${baseUrl}/public/v1/house/plan/:id/features?`;
export const EndpointSaveHousePlanDiscount = `${baseUrl}/v1/house/plan/discount`;
export const EndpointDeleteHousePlanDiscount = `${baseUrl}/v1/house/plan/discount/:id/delete`;
export const EndpointGetHousePlanDiscounts = `${baseUrl}/v1/house/plan/:id/discounts?`;
export const EndpointSaveFile = `${baseUrl}/public/v1/file/:id?`;
export const EndpointLandingPageImage = `${baseUrl}/public/v1/landing/page/file`;
export const EndpointDeleteFile = `${baseUrl}/v1/file/:id/delete`;
export const EndpointGetFiles = `${baseUrl}/public/v1/files?`;
export const EndpointGetDocuments = `${baseUrl}/public/v1/documents?`;
export const EndpointUploadDocument = `${baseUrl}/public/v1/file/:id/:documentType/document`;
export const EndpointGetLandingPageImages = `${baseUrl}/public/v1/landing/images?`;
export const EndpointGetFileBytes = `${baseUrl}/public/v1/file/:id/attachment`;
export const EndpointGetWalletTopUpTransactions = `${baseUrl}/v1/wallet/topup/transactions?`;
